<template>
  <div class="container_excel">
      <el-button type="primary" size="small" icon="el-icon-upload2" @click="openImport">{{$t('IMPORT')}}</el-button>
      <el-button type="primary" size="small" icon="el-icon-download" @click="openOut">{{$t('EXPORT')}}</el-button>
    <importExcel :id="type" :API="API" :innerAPI="innerAPI" :modelShow="showImport" @closeModel="closeModel"></importExcel>
  </div>
</template>

<script>
import importExcel from "@/components/importExport/importExcel";
import { getGrop } from '@/util/auth'
export default {
  components: {
    importExcel,
  },
  props:{
    API:String,
    innerAPI:String,
    outApi:String,
    type:{
      type:String,
      default:''
    }
  },
  name: "btn",
  data() {
    return {
      initModel: false,
      showImport: false,
    }
  },
  methods: {
    openImport() {
      this.initModel = true;
      this.showImport = true
    },
      openOut(e) {
          let type;
          if (this.$route.path == "/basic/workp") {
              type = "&type=" + 1;
          } else if (this.$route.path == "/basic/timp") {
              type = "&type=" + 2;
          } else {
              type = "";
          }
          if(this.type) {
            let categoryId
            if(this.type instanceof Object) {
              categoryId = this.type.id
            }else {
              categoryId = this.type
            }
            window.location.href = `${this.outApi}?categoryId=${categoryId}&corpId=${getGrop()}`
          } else {
            console.log(this.outApi + '?corpId=' + getGrop() + type)
              window.location.href = this.outApi + '?corpId=' + getGrop() + type
          }
          this.$message.success('导出成功')
      },
    closeModel() {
      this.showImport = false
      this.$emit('refresh', true)
    }
  },
  watch:{
  }
}
</script>

<style lang="scss" scoped>
.container_excel {
  display: inline-block;
}
</style>
