<template>
  <div class="volumeSet">
    <el-dialog
        :visible.sync="modelShow"
        width="35%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :append-to-body="true">
      <el-upload
          class="upload-demo"
          ref="file"
          action=""
          :auto-upload="false"
          :on-change="addExcel"
          accept=".xlsx,.xls"
          multiple
          :limit="1">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件，且不超过10MB</div>
      </el-upload>
      <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="clickDownloadExcel"> 下载模版</el-button>
        <el-button @click="closeModel">取 消</el-button>
        <el-button type="success" @click="makeSure">确 定</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "importExcel",
  props: {
    modelShow: {
      type: Boolean,
      default: false
    },
    API:String,
    innerAPI:String,
    id:{
      type:String,
      default: ''
    }
  },
  data() {
    return {
      fileList:[],
    }
  },
  created(){
    this.$refs['file'].clearFiles();

  },
  methods: {
    addExcel(file){
      console.log(file,'file')
      this.fileList = file;
    },
    // 下载模板
      clickDownloadExcel(e) {
          let id, type;
          if (this.$route.path == "/basic/workp") {
              window.location.href = this.API + "?type=1";
          } else if (this.$route.path == "/basic/timp") {
              window.location.href = this.API + "?type=2";
          } else {
              if (this.id) {
                  if (this.id instanceof Object) {
                      id = this.id.id
                  } else {
                      id = this.id
                  }
                  window.location.href = this.API + "?categoryId=" + id;
              } else {
                  window.location.href = this.API
              }
          }
    },
    closeModel() {
      this.$emit('closeModel', false)
    },
    makeSure() {
      if (!this.fileList.raw) return this.$message.error('请先上传文件')
      this.uploadFuFile();
      this.$emit('closeModel', false)
    },
    // 上传
    uploadFuFile(){
      return new Promise((resolve,reject)=>{
        if(!this.fileList.raw){
          resolve(false);
          return;
        }
          let formData = new FormData()
        if(this.id){
            let id
            if(this.id instanceof Object) {
                id = this.id.id
            }else {
                id = this.id
            }
            formData.append('categoryId', id)
        }
        if (this.$route.path == "/basic/workp") {
            formData.append('type', 1)
        } else if (this.$route.path == "/basic/timp") {
            formData.append('type', 2)
        }
        formData.append('file', this.fileList.raw)
        this.request.post(this.innerAPI,formData).then(res=>{
          console.log(res)
          if(res.data.code == '0000') this.$message.success(res.data.data.msg)
          this.$emit('closeModel', false)
        })
      })
    }
  },
  watch:{
    modelShow(New) {
      this.$refs['file'].clearFiles();
    }
  }
}
</script>

<style lang="scss" scoped>
.item_name {
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}
.dialog-footer {
  width: 100%;
  text-align: center;
}
/deep/.el-dialog__body {
  margin-bottom: 23px;
}
</style>
